@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    //TODO M3
  } @else {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    $background: map.get($theme, background);
    $foreground: map.get($theme, foreground);

    .map-sidenav-container {
      .collapse-button {
        background-color: mat.m2-get-color-from-palette($background, card);
      }

      .collapse-icon {
        color: mat.m2-get-color-from-palette($foreground, icon);
      }

      .mat-drawer {
        .mat-toolbar {
          background-color: mat.m2-get-color-from-palette($primary);
        }
      }
    }
  }
}
