@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .elements {
    padding: 5px;

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      position: relative;
      z-index: 0;

      padding: 5px;
      margin: 5px;

      border: 1px solid mat.m2-get-color-from-palette($primary, field-outline);
      border-radius: 5px;

      mat-icon {
        margin-right: 5px;
        cursor: move;
      }

      input {
        height: 18px;
        width: 38px;
      }
    }

    &__button {
      display: flex;
      margin-bottom: 5px;
      justify-content: center;
      align-items: flex-end;

      &__delete {
        margin-left: 10px;
      }
    }

    &__main_item {
      margin-bottom: 15px;
    }
  }

  .input-err {
    outline-color: mat.m2-get-color-from-palette($warn);
  }
}
