@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .viewport-list {
    background: mat.m2-get-color-from-palette($background, dialog);
    .selected {
      background-color: mat.m2-get-color-from-palette($background, hover) !important;
    }
  }

  .viewport-table {
    background: mat.m2-get-color-from-palette($background, dialog);
  }

  .topstock_icon svg {
    width: 20px !important;
    height: 20px;
  }

  .topstock_icon svg path {
    fill: mat.m2-get-color-from-palette($primary, 500) !important;
  }


  //.images-unavailable {
  //  background: mat.m2-get-color-from-palette($foreground, dividers) !important;
  //}
  //
  //.viewport-audited {
  //  background: mat.m2-get-color-from-palette($foreground, dividers, .02) !important;
  //}

  .mat-column-sortOrdinal {
    color: mat.m2-get-color-from-palette($foreground, secondary-text);
  }
}
