@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  mat-button-toggle {
    &.align-button {
      color: mat.m2-get-color-from-palette($primary);

      &.mat-button-toggle-checked {
        background: mat.m2-get-color-from-palette($primary);
        color: mat.m2-get-color-from-palette($primary, white);
      }

      &.mat-button-toggle-disabled {
        color: mat.m2-get-color-from-palette($foreground, disabled-text);
        background: mat.m2-get-color-from-palette($background, dialog);
      }
    }
  }

  .region-heading {
    color: mat.m2-get-color-from-palette($foreground, secondary-text);
  }
}
