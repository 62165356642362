@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .swatch {
    width: 175px;
    height: 75px;
  }

  .p50 {
    background: mat.m2-get-color-from-palette($primary, 50);
    color: mat.m2-get-contrast-color-from-palette($primary, 50);
  }
  .p100 {
    background: mat.m2-get-color-from-palette($primary, 100);
    color: mat.m2-get-contrast-color-from-palette($primary, 100);
  }
  .p200 {
    background: mat.m2-get-color-from-palette($primary, 200);
    color: mat.m2-get-contrast-color-from-palette($primary, 200);
  }
  .p300 {
    background: mat.m2-get-color-from-palette($primary, 300);
    color: mat.m2-get-contrast-color-from-palette($primary, 300);
  }
  .p400 {
    background: mat.m2-get-color-from-palette($primary, 400);
    color: mat.m2-get-contrast-color-from-palette($primary, 400);
  }
  .p500 {
    background: mat.m2-get-color-from-palette($primary, 500);
    color: mat.m2-get-contrast-color-from-palette($primary, 500);
  }
  .p600 {
    background: mat.m2-get-color-from-palette($primary, 600);
    color: mat.m2-get-contrast-color-from-palette($primary, 600);
  }
  .p700 {
    background: mat.m2-get-color-from-palette($primary, 700);
    color: mat.m2-get-contrast-color-from-palette($primary, 700);
  }
  .p800 {
    background: mat.m2-get-color-from-palette($primary, 800);
    color: mat.m2-get-contrast-color-from-palette($primary, 800);
  }
  .p900 {
    background: mat.m2-get-color-from-palette($primary, 900);
    color: mat.m2-get-contrast-color-from-palette($primary, 900);
  }
  .pA100 {
    background: mat.m2-get-color-from-palette($primary, A100);
    color: mat.m2-get-contrast-color-from-palette($primary, A100);
  }
  .pA200 {
    background: mat.m2-get-color-from-palette($primary, A200);
    color: mat.m2-get-contrast-color-from-palette($primary, A200);
  }
  .pA400 {
    background: mat.m2-get-color-from-palette($primary, A400);
    color: mat.m2-get-contrast-color-from-palette($primary, A400);
  }
  .pA700 {
    background: mat.m2-get-color-from-palette($primary, A700);
    color: mat.m2-get-contrast-color-from-palette($primary, A700);
  }

  .a50 {
    background: mat.m2-get-color-from-palette($accent, 50);
    color: mat.m2-get-contrast-color-from-palette($accent, 50);
  }
  .a100 {
    background: mat.m2-get-color-from-palette($accent, 100);
    color: mat.m2-get-contrast-color-from-palette($accent, 100);
  }
  .a200 {
    background: mat.m2-get-color-from-palette($accent, 200);
    color: mat.m2-get-contrast-color-from-palette($accent, 200);
  }
  .a300 {
    background: mat.m2-get-color-from-palette($accent, 300);
    color: mat.m2-get-contrast-color-from-palette($accent, 300);
  }
  .a400 {
    background: mat.m2-get-color-from-palette($accent, 400);
    color: mat.m2-get-contrast-color-from-palette($accent, 400);
  }
  .a500 {
    background: mat.m2-get-color-from-palette($accent, 500);
    color: mat.m2-get-contrast-color-from-palette($accent, 500);
  }
  .a600 {
    background: mat.m2-get-color-from-palette($accent, 600);
    color: mat.m2-get-contrast-color-from-palette($accent, 600);
  }
  .a700 {
    background: mat.m2-get-color-from-palette($accent, 700);
    color: mat.m2-get-contrast-color-from-palette($accent, 700);
  }
  .a800 {
    background: mat.m2-get-color-from-palette($accent, 800);
    color: mat.m2-get-contrast-color-from-palette($accent, 800);
  }
  .a900 {
    background: mat.m2-get-color-from-palette($accent, 900);
    color: mat.m2-get-contrast-color-from-palette($accent, 900);
  }
  .aA100 {
    background: mat.m2-get-color-from-palette($accent, A100);
    color: mat.m2-get-contrast-color-from-palette($accent, A100);
  }
  .aA200 {
    background: mat.m2-get-color-from-palette($accent, A200);
    color: mat.m2-get-contrast-color-from-palette($accent, A200);
  }
  .aA400 {
    background: mat.m2-get-color-from-palette($accent, A400);
    color: mat.m2-get-contrast-color-from-palette($accent, A400);
  }
  .aA700 {
    background: mat.m2-get-color-from-palette($accent, A700);
    color: mat.m2-get-contrast-color-from-palette($accent, A700);
  }
  .w50 {
    background: mat.m2-get-color-from-palette($warn, 50);
    color: mat.m2-get-contrast-color-from-palette($warn, 50);
  }
  .w100 {
    background: mat.m2-get-color-from-palette($warn, 100);
    color: mat.m2-get-contrast-color-from-palette($warn, 100);
  }
  .w200 {
    background: mat.m2-get-color-from-palette($warn, 200);
    color: mat.m2-get-contrast-color-from-palette($warn, 200);
  }
  .w300 {
    background: mat.m2-get-color-from-palette($warn, 300);
    color: mat.m2-get-contrast-color-from-palette($warn, 300);
  }
  .w400 {
    background: mat.m2-get-color-from-palette($warn, 400);
    color: mat.m2-get-contrast-color-from-palette($warn, 400);
  }
  .w500 {
    background: mat.m2-get-color-from-palette($warn, 500);
    color: mat.m2-get-contrast-color-from-palette($warn, 500);
  }
  .w600 {
    background: mat.m2-get-color-from-palette($warn, 600);
    color: mat.m2-get-contrast-color-from-palette($warn, 600);
  }
  .w700 {
    background: mat.m2-get-color-from-palette($warn, 700);
    color: mat.m2-get-contrast-color-from-palette($warn, 700);
  }
  .w800 {
    background: mat.m2-get-color-from-palette($warn, 800);
    color: mat.m2-get-contrast-color-from-palette($warn, 800);
  }
  .w900 {
    background: mat.m2-get-color-from-palette($warn, 900);
    color: mat.m2-get-contrast-color-from-palette($warn, 900);
  }
  .wA100 {
    background: mat.m2-get-color-from-palette($warn, A100);
    color: mat.m2-get-contrast-color-from-palette($warn, A100);
  }
  .wA200 {
    background: mat.m2-get-color-from-palette($warn, A200);
    color: mat.m2-get-contrast-color-from-palette($warn, A200);
  }
  .wA400 {
    background: mat.m2-get-color-from-palette($warn, A400);
    color: mat.m2-get-contrast-color-from-palette($warn, A400);
  }
  .wA700 {
    background: mat.m2-get-color-from-palette($warn, A700);
    color: mat.m2-get-contrast-color-from-palette($warn, A700);
  }

  .background-status-bar {
    background: mat.m2-get-color-from-palette($background, status-bar);
  }
  .background-app-bar {
    background: mat.m2-get-color-from-palette($background, app-bar);
  }
  .background-background {
    background: mat.m2-get-color-from-palette($background, background);
  }
  .background-hover {
    background: mat.m2-get-color-from-palette($background, hover);
  }
  .background-card {
    background: mat.m2-get-color-from-palette($background, card);
  }
  .background-dialog {
    background: mat.m2-get-color-from-palette($background, dialog);
  }
  .background-disabled-button {
    background: mat.m2-get-color-from-palette($background, disabled-button);
  }
  .background-raised-button {
    background: mat.m2-get-color-from-palette($background, raised-button);
  }
  .background-focused-button {
    background: mat.m2-get-color-from-palette($background, focused-button);
  }
  .background-selected-button {
    background: mat.m2-get-color-from-palette($background, selected-button);
  }
  .background-selected-disabled-button {
    background: mat.m2-get-color-from-palette($background, selected-disabled-button);
  }
  .background-disabled-button-toggle {
    background: mat.m2-get-color-from-palette($background, disabled-button-toggle);
  }
  .background-unselected-chip {
    background: mat.m2-get-color-from-palette($background, unselected-chip);
  }
  .background-disabled-list-option {
    background: mat.m2-get-color-from-palette($background, disabled-list-option);
  }
  .background-tooltip {
    background: mat.m2-get-color-from-palette($background, tooltip);
  }

  .foreground-base {
    background: mat.m2-get-color-from-palette($foreground, base);
  }
  .foreground-divider {
    background: mat.m2-get-color-from-palette($foreground, divider);
  }
  .foreground-dividers {
    background: mat.m2-get-color-from-palette($foreground, dividers);
  }
  .foreground-disabled {
    background: mat.m2-get-color-from-palette($foreground, disabled);
  }
  .foreground-disabled-button {
    background: mat.m2-get-color-from-palette($foreground, disabled-button);
  }
  .foreground-disabled-text {
    background: mat.m2-get-color-from-palette($foreground, disabled-text);
  }
  .foreground-elevation {
    background: mat.m2-get-color-from-palette($foreground, elevation);
  }
  .foreground-hint-text {
    background: mat.m2-get-color-from-palette($foreground, hint-text);
  }
  .foreground-secondary-text {
    background: mat.m2-get-color-from-palette($foreground, secondary-text);
  }
  .foreground-icon {
    background: mat.m2-get-color-from-palette($foreground, icon);
  }
  .foreground-icons {
    background: mat.m2-get-color-from-palette($foreground, icons);
  }
  .foreground-text {
    background: mat.m2-get-color-from-palette($foreground, text);
  }
  .foreground-slider-min {
    background: mat.m2-get-color-from-palette($foreground, slider-min);
  }
  .foreground-slider-off {
    background: mat.m2-get-color-from-palette($foreground, slider-off);
  }
  .foreground-slider-off-active {
    background: mat.m2-get-color-from-palette($foreground, slider-off-active);
  }
}
