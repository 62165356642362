@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .base-scan-container {
    border: 1px solid mat.m2-get-color-from-palette($foreground, divider);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    overflow: hidden;
  }

  .warning {
    background: mat.m2-get-color-from-palette($warn);
    color: mat.m2-get-color-from-palette($warn, A100);
  }

  .instruction-header {
    color: mat.m2-get-color-from-palette($accent);
  }

  .instruction {
    color: mat.m2-get-color-from-palette($foreground, secondary-text);
  }

  .tag-tooltip{
    background: mat.m2-get-color-from-palette($background, dialog);
    border: 2px solid mat.m2-get-color-from-palette($primary);
  }

  .tag-tooltip-component {
    color: mat.m2-get-color-from-palette($primary);
  }

  .stepper-toggle-panel {
    .mat-button-toggle {
      color: mat.m2-get-color-from-palette($primary);

      &.mat-button-toggle-checked {
        color: mat.m2-get-color-from-palette($background, dialog);
        background: mat.m2-get-color-from-palette($primary);
      }

      &.mat-button-toggle-disabled {
        background: mat.m2-get-color-from-palette($background, disabled-button);
      }
    }
  }

  .tag-disabled {
    color: mat.m2-get-color-from-palette($accent);
  }
}
