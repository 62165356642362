@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .instruction-div {
    color: mat.m2-get-color-from-palette($primary);
    background-color: mat.m2-get-color-from-palette($background, dialog);
  }

  .section-heading {
    background-color: mat.m2-get-color-from-palette($background, dialog);
  }

  .audit-control-panel {
    background-color: mat.m2-get-color-from-palette($background, dialog);
  }

  .confirmed-issues {
    color: mat.m2-get-color-from-palette($foreground, disabled-text);
  }

  .audit-content {
    .mat-expansion-panel {
      background-color: mat.m2-get-color-from-palette($background);
    }
  }

  .step-heading {
    color: mat.m2-get-color-from-palette($primary);
  }

  :host ::ng-deep .mat-step-header .mat-step-icon {
    background-color: mat.m2-get-color-from-palette($primary);
  }
}
