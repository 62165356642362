@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    .review-age-chip.mat-mdc-chip {
      .mdc-evolution-chip__icon--primary, .mdc-evolution-chip__icon--trailing {
        color: mat.get-theme-color($theme, primary) !important;
      }

      &.mat-mdc-standard-chip, .mat-mdc-button {
        background-color: white;
        color: mat.get-theme-color($theme, primary);
      }

      .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
        color: mat.get-theme-color($theme, primary);
      }
    }

    .review-age-urgent-chip.mat-mdc-chip {
      .mdc-evolution-chip__icon--primary, .mdc-evolution-chip__icon--trailing {
        color: mat.get-theme-color($theme, error, 70) !important;
      }

      .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
        color: mat.get-theme-color($theme, error, 70);
      }

      &.mat-mdc-standard-chip, .mat-mdc-button {
        background-color: white;
        color: mat.get-theme-color($theme, error, 70);
      }
    }

  } @else {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    $background: map.get($theme, background);
    $foreground: map.get($theme, foreground);

    .review-age-chip.mat-mdc-chip {
      .mdc-evolution-chip__icon--primary, .mdc-evolution-chip__icon--trailing {
        color: mat.m2-get-color-from-palette($primary) !important;
      }

      &.mat-mdc-standard-chip, .mat-mdc-button {
        background-color: white;
        color: mat.m2-get-color-from-palette($primary);
      }

      .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
        color: mat.m2-get-color-from-palette($primary);
      }
    }

    .review-age-urgent-chip.mat-mdc-chip {
      .mdc-evolution-chip__icon--primary, .mdc-evolution-chip__icon--trailing {
        color: mat.m2-get-color-from-palette($warn, darker) !important;
      }

      .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
        color: mat.m2-get-color-from-palette($warn, darker);
      }

      &.mat-mdc-standard-chip, .mat-mdc-button {
        background-color: white;
        color: mat.m2-get-color-from-palette($warn, darker);
      }
    }
  }
}
