@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    //TODO M3
  } @else {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    $background: map.get($theme, background);
    $foreground: map.get($theme, foreground);

    .container {
      color: mat.m2-get-color-from-palette($foreground, text);
    }

    .option-list {
      color: mat.m2-get-color-from-palette($foreground, text);
    }

    .title {
      color: mat.m2-get-color-from-palette($foreground, secondary-text);
    }

    .searchBar {
      background: mat.m2-get-color-from-palette($background);

      input {
        background: mat.m2-get-color-from-palette($background);
      }
    }
  }
}
