html {
  overflow-y: initial !important;
}

html.cdk-global-scrollblock body {
  overflow-y: scroll;
}

body {
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  margin: 0;
}

main {
  margin: 10px;
}

.hide {
  display: none;
}

.clickable {
  cursor: hand;
}

.wrap {
  white-space: normal;
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-mdc-list.dense-detail-list .mat-mdc-list-item-title {
  font-size: 12px;
}
.mat-mdc-list.dense-detail-list .mat-mdc-list-item-line {
  font-size: 12px;
}

.mat-mdc-card-title {
  padding: 15px;
}

.header-text {
  font-size: 18px;
  font-weight: 500;
}

.selectPopupFull {
  min-width: max-content;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.badger-nav-bar {
  background: linear-gradient(310deg, rgb(31, 61, 96) 0%, rgb(19, 37, 58) 100%) !important;
}

html {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #028aff;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #4eadff;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #d1685f;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

html {
  --mat-optgroup-label-text-color: white;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #4eadff;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
html {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #4eadff;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #028aff;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.mat-primary {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #028aff;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #4eadff;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.mat-accent {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #4eadff;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #d1685f;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
}
.mat-warn {
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #d1685f;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}

html {
  --mat-app-background-color: #303030;
  --mat-app-text-color: white;
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-option-label-text-font: Roboto, sans-serif;
  --mat-option-label-text-line-height: 24px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto, sans-serif;
  --mat-optgroup-label-text-line-height: 24px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
}
html {
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}
html {
  --mdc-elevated-card-container-color: #424242;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-outlined-card-container-color: #424242;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
}

html {
  --mat-card-title-text-font: Roboto, sans-serif;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto, sans-serif;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #028aff;
  --mdc-linear-progress-track-color: rgba(2, 138, 255, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #4eadff;
  --mdc-linear-progress-track-color: rgba(78, 173, 255, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #d1685f;
  --mdc-linear-progress-track-color: rgba(209, 104, 95, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto, sans-serif;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
}
html {
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}
html {
  --mdc-filled-text-field-caret-color: #028aff;
  --mdc-filled-text-field-focus-active-indicator-color: #028aff;
  --mdc-filled-text-field-focus-label-text-color: rgba(2, 138, 255, 0.87);
  --mdc-filled-text-field-container-color: rgb(73.56, 73.56, 73.56);
  --mdc-filled-text-field-disabled-container-color: rgb(69.78, 69.78, 69.78);
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #d1685f;
  --mdc-filled-text-field-error-focus-label-text-color: #d1685f;
  --mdc-filled-text-field-error-label-text-color: #d1685f;
  --mdc-filled-text-field-error-caret-color: #d1685f;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #d1685f;
  --mdc-filled-text-field-error-focus-active-indicator-color: #d1685f;
  --mdc-filled-text-field-error-hover-active-indicator-color: #d1685f;
}
html {
  --mdc-outlined-text-field-caret-color: #028aff;
  --mdc-outlined-text-field-focus-outline-color: #028aff;
  --mdc-outlined-text-field-focus-label-text-color: rgba(2, 138, 255, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #d1685f;
  --mdc-outlined-text-field-error-focus-label-text-color: #d1685f;
  --mdc-outlined-text-field-error-label-text-color: #d1685f;
  --mdc-outlined-text-field-error-hover-label-text-color: #d1685f;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #d1685f;
  --mdc-outlined-text-field-error-hover-outline-color: #d1685f;
  --mdc-outlined-text-field-error-outline-color: #d1685f;
}
html {
  --mat-form-field-focus-select-arrow-color: rgba(2, 138, 255, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #d1685f;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #4eadff;
  --mdc-filled-text-field-focus-active-indicator-color: #4eadff;
  --mdc-filled-text-field-focus-label-text-color: rgba(78, 173, 255, 0.87);
}
.mat-mdc-form-field.mat-accent {
  --mdc-outlined-text-field-caret-color: #4eadff;
  --mdc-outlined-text-field-focus-outline-color: #4eadff;
  --mdc-outlined-text-field-focus-label-text-color: rgba(78, 173, 255, 0.87);
}
.mat-mdc-form-field.mat-accent {
  --mat-form-field-focus-select-arrow-color: rgba(78, 173, 255, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #d1685f;
  --mdc-filled-text-field-focus-active-indicator-color: #d1685f;
  --mdc-filled-text-field-focus-label-text-color: rgba(209, 104, 95, 0.87);
}
.mat-mdc-form-field.mat-warn {
  --mdc-outlined-text-field-caret-color: #d1685f;
  --mdc-outlined-text-field-focus-outline-color: #d1685f;
  --mdc-outlined-text-field-focus-label-text-color: rgba(209, 104, 95, 0.87);
}
.mat-mdc-form-field.mat-warn {
  --mat-form-field-focus-select-arrow-color: rgba(209, 104, 95, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto, sans-serif;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
}
html {
  --mdc-outlined-text-field-label-text-font: Roboto, sans-serif;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
}
html {
  --mat-form-field-container-text-font: Roboto, sans-serif;
  --mat-form-field-container-text-line-height: 24px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto, sans-serif;
  --mat-form-field-subscript-text-line-height: 20px;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(2, 138, 255, 0.87);
  --mat-select-invalid-arrow-color: rgba(209, 104, 95, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(78, 173, 255, 0.87);
  --mat-select-invalid-arrow-color: rgba(209, 104, 95, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(209, 104, 95, 0.87);
  --mat-select-invalid-arrow-color: rgba(209, 104, 95, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: Roboto, sans-serif;
  --mat-select-trigger-text-line-height: 24px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: #424242;
}

html {
  --mdc-dialog-container-shape: 4px;
}
html {
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
html {
  --mdc-dialog-subhead-font: Roboto, sans-serif;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto, sans-serif;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}
.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
}
.mat-mdc-standard-chip {
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-elevated-selected-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-elevated-disabled-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-flat-disabled-selected-container-color: rgb(88.68, 88.68, 88.68);
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.mat-mdc-standard-chip {
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #028aff;
  --mdc-chip-elevated-selected-container-color: #028aff;
  --mdc-chip-elevated-disabled-container-color: #028aff;
  --mdc-chip-flat-disabled-selected-container-color: #028aff;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-elevated-container-color: #4eadff;
  --mdc-chip-elevated-selected-container-color: #4eadff;
  --mdc-chip-elevated-disabled-container-color: #4eadff;
  --mdc-chip-flat-disabled-selected-container-color: #4eadff;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-selected-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-disabled-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-trailing-icon-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mat-chip-selected-disabled-trailing-icon-color: rgba(0, 0, 0, 0.87);
  --mat-chip-selected-trailing-icon-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #d1685f;
  --mdc-chip-elevated-selected-container-color: #d1685f;
  --mdc-chip-elevated-disabled-container-color: #d1685f;
  --mdc-chip-flat-disabled-selected-container-color: #d1685f;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}
.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto, sans-serif;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}
html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #4eadff;
  --mdc-switch-selected-handle-color: #4eadff;
  --mdc-switch-selected-hover-state-layer-color: #4eadff;
  --mdc-switch-selected-pressed-state-layer-color: #4eadff;
  --mdc-switch-selected-focus-handle-color: #81c5ff;
  --mdc-switch-selected-hover-handle-color: #81c5ff;
  --mdc-switch-selected-pressed-handle-color: #81c5ff;
  --mdc-switch-selected-focus-track-color: #0282ff;
  --mdc-switch-selected-hover-track-color: #0282ff;
  --mdc-switch-selected-pressed-track-color: #0282ff;
  --mdc-switch-selected-track-color: #0282ff;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-switch-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: white;
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #4eadff;
  --mdc-switch-selected-handle-color: #4eadff;
  --mdc-switch-selected-hover-state-layer-color: #4eadff;
  --mdc-switch-selected-pressed-state-layer-color: #4eadff;
  --mdc-switch-selected-focus-handle-color: #81c5ff;
  --mdc-switch-selected-hover-handle-color: #81c5ff;
  --mdc-switch-selected-pressed-handle-color: #81c5ff;
  --mdc-switch-selected-focus-track-color: #0282ff;
  --mdc-switch-selected-hover-track-color: #0282ff;
  --mdc-switch-selected-pressed-track-color: #0282ff;
  --mdc-switch-selected-track-color: #0282ff;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #df958f;
  --mdc-switch-selected-handle-color: #df958f;
  --mdc-switch-selected-hover-state-layer-color: #df958f;
  --mdc-switch-selected-pressed-state-layer-color: #df958f;
  --mdc-switch-selected-focus-handle-color: #e8b4af;
  --mdc-switch-selected-hover-handle-color: #e8b4af;
  --mdc-switch-selected-pressed-handle-color: #e8b4af;
  --mdc-switch-selected-focus-track-color: #cc6057;
  --mdc-switch-selected-hover-track-color: #cc6057;
  --mdc-switch-selected-pressed-track-color: #cc6057;
  --mdc-switch-selected-track-color: #cc6057;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Roboto, sans-serif;
  --mat-switch-label-text-line-height: 20px;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: 0.0178571429em;
  --mat-switch-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #028aff;
  --mdc-radio-selected-hover-icon-color: #028aff;
  --mdc-radio-selected-icon-color: #028aff;
  --mdc-radio-selected-pressed-icon-color: #028aff;
}
.mat-mdc-radio-button.mat-primary {
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #028aff;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #4eadff;
  --mdc-radio-selected-hover-icon-color: #4eadff;
  --mdc-radio-selected-icon-color: #4eadff;
  --mdc-radio-selected-pressed-icon-color: #4eadff;
}
.mat-mdc-radio-button.mat-accent {
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #4eadff;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #d1685f;
  --mdc-radio-selected-hover-icon-color: #d1685f;
  --mdc-radio-selected-icon-color: #d1685f;
  --mdc-radio-selected-pressed-icon-color: #d1685f;
}
.mat-mdc-radio-button.mat-warn {
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #d1685f;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}

html {
  --mdc-radio-state-layer-size: 40px;
}
html {
  --mat-radio-touch-target-display: block;
}

html {
  --mat-radio-label-text-font: Roboto, sans-serif;
  --mat-radio-label-text-line-height: 20px;
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: 0.0178571429em;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: #028aff;
  --mdc-slider-focus-handle-color: #028aff;
  --mdc-slider-hover-handle-color: #028aff;
  --mdc-slider-active-track-color: #028aff;
  --mdc-slider-inactive-track-color: #028aff;
  --mdc-slider-with-tick-marks-inactive-container-color: #028aff;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
}
html {
  --mat-slider-ripple-color: #028aff;
  --mat-slider-hover-state-layer-color: rgba(2, 138, 255, 0.05);
  --mat-slider-focus-state-layer-color: rgba(2, 138, 255, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
}
html .mat-accent {
  --mdc-slider-handle-color: #4eadff;
  --mdc-slider-focus-handle-color: #4eadff;
  --mdc-slider-hover-handle-color: #4eadff;
  --mdc-slider-active-track-color: #4eadff;
  --mdc-slider-inactive-track-color: #4eadff;
  --mdc-slider-with-tick-marks-inactive-container-color: #4eadff;
  --mdc-slider-with-tick-marks-active-container-color: rgba(0, 0, 0, 0.87);
}
html .mat-accent {
  --mat-slider-ripple-color: #4eadff;
  --mat-slider-hover-state-layer-color: rgba(78, 173, 255, 0.05);
  --mat-slider-focus-state-layer-color: rgba(78, 173, 255, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #d1685f;
  --mdc-slider-focus-handle-color: #d1685f;
  --mdc-slider-hover-handle-color: #d1685f;
  --mdc-slider-active-track-color: #d1685f;
  --mdc-slider-inactive-track-color: #d1685f;
  --mdc-slider-with-tick-marks-inactive-container-color: #d1685f;
  --mdc-slider-with-tick-marks-active-container-color: white;
}
html .mat-warn {
  --mat-slider-ripple-color: #d1685f;
  --mat-slider-hover-state-layer-color: rgba(209, 104, 95, 0.05);
  --mat-slider-focus-state-layer-color: rgba(209, 104, 95, 0.2);
}

html {
  --mdc-slider-label-label-text-font: Roboto, sans-serif;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #424242;
  --mat-menu-divider-color: rgba(255, 255, 255, 0.12);
}

html {
  --mat-menu-item-label-text-font: Roboto, sans-serif;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 24px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
}
html {
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #028aff;
  --mdc-radio-selected-hover-icon-color: #028aff;
  --mdc-radio-selected-icon-color: #028aff;
  --mdc-radio-selected-pressed-icon-color: #028aff;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #4eadff;
  --mdc-radio-selected-hover-icon-color: #4eadff;
  --mdc-radio-selected-icon-color: #4eadff;
  --mdc-radio-selected-pressed-icon-color: #4eadff;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #d1685f;
  --mdc-radio-selected-hover-icon-color: #d1685f;
  --mdc-radio-selected-icon-color: #d1685f;
  --mdc-radio-selected-pressed-icon-color: #d1685f;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #028aff;
  --mdc-checkbox-selected-hover-icon-color: #028aff;
  --mdc-checkbox-selected-icon-color: #028aff;
  --mdc-checkbox-selected-pressed-icon-color: #028aff;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #028aff;
  --mdc-checkbox-selected-hover-state-layer-color: #028aff;
  --mdc-checkbox-selected-pressed-state-layer-color: #028aff;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #4eadff;
  --mdc-checkbox-selected-hover-icon-color: #4eadff;
  --mdc-checkbox-selected-icon-color: #4eadff;
  --mdc-checkbox-selected-pressed-icon-color: #4eadff;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #4eadff;
  --mdc-checkbox-selected-hover-state-layer-color: #4eadff;
  --mdc-checkbox-selected-pressed-state-layer-color: #4eadff;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #d1685f;
  --mdc-checkbox-selected-hover-icon-color: #d1685f;
  --mdc-checkbox-selected-icon-color: #d1685f;
  --mdc-checkbox-selected-pressed-icon-color: #d1685f;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #d1685f;
  --mdc-checkbox-selected-hover-state-layer-color: #d1685f;
  --mdc-checkbox-selected-pressed-state-layer-color: #d1685f;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #028aff;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}
html {
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: Roboto, sans-serif;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto, sans-serif;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}
.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto, sans-serif;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #424242;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mat-paginator-container-text-font: Roboto, sans-serif;
  --mat-paginator-container-text-line-height: 20px;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
html {
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
}
html {
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #028aff;
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #028aff;
  --mat-tab-header-active-ripple-color: #028aff;
  --mat-tab-header-inactive-ripple-color: #028aff;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #028aff;
  --mat-tab-header-active-hover-label-text-color: #028aff;
  --mat-tab-header-active-focus-indicator-color: #028aff;
  --mat-tab-header-active-hover-indicator-color: #028aff;
}
.mat-mdc-tab-group.mat-accent,
.mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #4eadff;
}
.mat-mdc-tab-group.mat-accent,
.mat-mdc-tab-nav-bar.mat-accent {
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #4eadff;
  --mat-tab-header-active-ripple-color: #4eadff;
  --mat-tab-header-inactive-ripple-color: #4eadff;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #4eadff;
  --mat-tab-header-active-hover-label-text-color: #4eadff;
  --mat-tab-header-active-focus-indicator-color: #4eadff;
  --mat-tab-header-active-hover-indicator-color: #4eadff;
}
.mat-mdc-tab-group.mat-warn,
.mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #d1685f;
}
.mat-mdc-tab-group.mat-warn,
.mat-mdc-tab-nav-bar.mat-warn {
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #d1685f;
  --mat-tab-header-active-ripple-color: #d1685f;
  --mat-tab-header-inactive-ripple-color: #d1685f;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #d1685f;
  --mat-tab-header-active-hover-label-text-color: #d1685f;
  --mat-tab-header-active-focus-indicator-color: #d1685f;
  --mat-tab-header-active-hover-indicator-color: #d1685f;
}
.mat-mdc-tab-group.mat-background-primary,
.mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #028aff;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent,
.mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #4eadff;
  --mat-tab-header-with-background-foreground-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-tab-group.mat-background-warn,
.mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #d1685f;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto, sans-serif;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: 0.0892857143em;
  --mat-tab-header-label-text-line-height: 36px;
  --mat-tab-header-label-text-weight: 500;
}
html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}
html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: black;
  --mdc-checkbox-selected-focus-icon-color: #4eadff;
  --mdc-checkbox-selected-hover-icon-color: #4eadff;
  --mdc-checkbox-selected-icon-color: #4eadff;
  --mdc-checkbox-selected-pressed-icon-color: #4eadff;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #4eadff;
  --mdc-checkbox-selected-hover-state-layer-color: #4eadff;
  --mdc-checkbox-selected-pressed-state-layer-color: #4eadff;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
html {
  --mat-checkbox-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-checkbox-label-text-color: white;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #028aff;
  --mdc-checkbox-selected-hover-icon-color: #028aff;
  --mdc-checkbox-selected-icon-color: #028aff;
  --mdc-checkbox-selected-pressed-icon-color: #028aff;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #028aff;
  --mdc-checkbox-selected-hover-state-layer-color: #028aff;
  --mdc-checkbox-selected-pressed-state-layer-color: #028aff;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #d1685f;
  --mdc-checkbox-selected-hover-icon-color: #d1685f;
  --mdc-checkbox-selected-icon-color: #d1685f;
  --mdc-checkbox-selected-pressed-icon-color: #d1685f;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #d1685f;
  --mdc-checkbox-selected-hover-state-layer-color: #d1685f;
  --mdc-checkbox-selected-pressed-state-layer-color: #d1685f;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}
html {
  --mat-checkbox-touch-target-display: block;
}

html {
  --mat-checkbox-label-text-font: Roboto, sans-serif;
  --mat-checkbox-label-text-line-height: 20px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: 0.0178571429em;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
}
html {
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
}
html {
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
}
html {
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
}
html {
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
}
html {
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
}
html {
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html {
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
}
html {
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
}
html {
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
}
html {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
}
html {
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
html {
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #028aff;
}
.mat-mdc-button.mat-primary {
  --mat-text-button-state-layer-color: #028aff;
  --mat-text-button-ripple-color: rgba(2, 138, 255, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #4eadff;
}
.mat-mdc-button.mat-accent {
  --mat-text-button-state-layer-color: #4eadff;
  --mat-text-button-ripple-color: rgba(78, 173, 255, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #d1685f;
}
.mat-mdc-button.mat-warn {
  --mat-text-button-state-layer-color: #d1685f;
  --mat-text-button-ripple-color: rgba(209, 104, 95, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #028aff;
  --mdc-filled-button-label-text-color: white;
}
.mat-mdc-unelevated-button.mat-primary {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #4eadff;
  --mdc-filled-button-label-text-color: black;
}
.mat-mdc-unelevated-button.mat-accent {
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #d1685f;
  --mdc-filled-button-label-text-color: white;
}
.mat-mdc-unelevated-button.mat-warn {
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #028aff;
  --mdc-protected-button-label-text-color: white;
}
.mat-mdc-raised-button.mat-primary {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #4eadff;
  --mdc-protected-button-label-text-color: black;
}
.mat-mdc-raised-button.mat-accent {
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #d1685f;
  --mdc-protected-button-label-text-color: white;
}
.mat-mdc-raised-button.mat-warn {
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #028aff;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.mat-mdc-outlined-button.mat-primary {
  --mat-outlined-button-state-layer-color: #028aff;
  --mat-outlined-button-ripple-color: rgba(2, 138, 255, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #4eadff;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.mat-mdc-outlined-button.mat-accent {
  --mat-outlined-button-state-layer-color: #4eadff;
  --mat-outlined-button-ripple-color: rgba(78, 173, 255, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #d1685f;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.mat-mdc-outlined-button.mat-warn {
  --mat-outlined-button-state-layer-color: #d1685f;
  --mat-outlined-button-ripple-color: rgba(209, 104, 95, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
}
html {
  --mdc-filled-button-container-height: 36px;
}
html {
  --mdc-protected-button-container-height: 36px;
}
html {
  --mdc-outlined-button-container-height: 36px;
}
html {
  --mat-text-button-touch-target-display: block;
}
html {
  --mat-filled-button-touch-target-display: block;
}
html {
  --mat-protected-button-touch-target-display: block;
}
html {
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: Roboto, sans-serif;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: 0.0892857143em;
  --mdc-text-button-label-text-weight: 500;
  --mdc-text-button-label-text-transform: none;
}
html {
  --mdc-filled-button-label-text-font: Roboto, sans-serif;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: 0.0892857143em;
  --mdc-filled-button-label-text-weight: 500;
  --mdc-filled-button-label-text-transform: none;
}
html {
  --mdc-protected-button-label-text-font: Roboto, sans-serif;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: 0.0892857143em;
  --mdc-protected-button-label-text-weight: 500;
  --mdc-protected-button-label-text-transform: none;
}
html {
  --mdc-outlined-button-label-text-font: Roboto, sans-serif;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: 0.0892857143em;
  --mdc-outlined-button-label-text-weight: 500;
  --mdc-outlined-button-label-text-transform: none;
}
html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
html {
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #028aff;
}
html .mat-mdc-icon-button.mat-primary {
  --mat-icon-button-state-layer-color: #028aff;
  --mat-icon-button-ripple-color: rgba(2, 138, 255, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #4eadff;
}
html .mat-mdc-icon-button.mat-accent {
  --mat-icon-button-state-layer-color: #4eadff;
  --mat-icon-button-ripple-color: rgba(78, 173, 255, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #d1685f;
}
html .mat-mdc-icon-button.mat-warn {
  --mat-icon-button-state-layer-color: #d1685f;
  --mat-icon-button-ripple-color: rgba(209, 104, 95, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
html {
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-fab-container-color: #424242;
}
html {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
}
html {
  --mdc-fab-small-container-color: #424242;
}
html {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #028aff;
}
html .mat-mdc-fab.mat-primary {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #4eadff;
}
html .mat-mdc-fab.mat-accent {
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #d1685f;
}
html .mat-mdc-fab.mat-warn {
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #028aff;
}
html .mat-mdc-mini-fab.mat-primary {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #4eadff;
}
html .mat-mdc-mini-fab.mat-accent {
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #d1685f;
}
html .mat-mdc-mini-fab.mat-warn {
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
}
html {
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: Roboto, sans-serif;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: 0.0892857143em;
  --mdc-extended-fab-label-text-weight: 500;
}
html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: rgb(217.2, 217.2, 217.2);
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
}
html {
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
}

html {
  --mdc-snackbar-supporting-text-font: Roboto, sans-serif;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: #424242;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Roboto, sans-serif;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Roboto, sans-serif;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Roboto, sans-serif;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #028aff;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #4eadff;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #d1685f;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #028aff;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: rgb(110.1, 110.1, 110.1);
  --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.5);
}

.mat-badge-accent {
  --mat-badge-background-color: #4eadff;
  --mat-badge-text-color: rgba(0, 0, 0, 0.87);
}

.mat-badge-warn {
  --mat-badge-background-color: #d1685f;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Roboto, sans-serif;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #424242;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto, sans-serif;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
}
html {
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.5);
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.7);
  --mat-legacy-button-toggle-selected-state-background-color: #212121;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #424242;
}
html {
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #424242;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #212121;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-standard-button-toggle-disabled-state-background-color: #424242;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-divider-color: rgb(88.68, 88.68, 88.68);
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-legacy-button-toggle-label-text-line-height: 24px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
}
html {
  --mat-standard-button-toggle-label-text-font: Roboto, sans-serif;
  --mat-standard-button-toggle-label-text-line-height: 24px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #028aff;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(2, 138, 255, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(2, 138, 255, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(2, 138, 255, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #028aff;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(2, 138, 255, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: #424242;
  --mat-datepicker-calendar-container-text-color: white;
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-selected-state-background-color: #4eadff;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(78, 173, 255, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(78, 173, 255, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(78, 173, 255, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(78, 173, 255, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #d1685f;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(209, 104, 95, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(209, 104, 95, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(209, 104, 95, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(209, 104, 95, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: rgb(69.5241935484, 163.4758064516, 93.9516129032);
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #4eadff;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #d1685f;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Roboto, sans-serif;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 500;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 500;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(255, 255, 255, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: #424242;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
  --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Roboto, sans-serif;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto, sans-serif;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #028aff;
}
.mat-icon.mat-accent {
  --mat-icon-color: #4eadff;
}
.mat-icon.mat-warn {
  --mat-icon-color: #d1685f;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #424242;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #303030;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(189, 189, 189, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #028aff;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #028aff;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #028aff;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #424242;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #d1685f;
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-error-state-icon-foreground-color: #d1685f;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-selected-state-icon-background-color: #4eadff;
  --mat-stepper-header-selected-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-done-state-icon-background-color: #4eadff;
  --mat-stepper-header-done-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-edit-state-icon-background-color: #4eadff;
  --mat-stepper-header-edit-state-icon-foreground-color: rgba(0, 0, 0, 0.87);
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #d1685f;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #d1685f;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #d1685f;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-font: Roboto, sans-serif;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: rgb(198.3, 198.3, 198.3);
}

html {
  --mat-toolbar-container-background-color: #212121;
  --mat-toolbar-container-text-color: white;
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #028aff;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #4eadff;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #d1685f;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Roboto, sans-serif;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: #424242;
  --mat-tree-node-text-color: white;
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: Roboto, sans-serif;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

html {
  --mat-timepicker-container-shape: 4px;
  --mat-timepicker-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-timepicker-container-background-color: #424242;
}

.mat-app-background {
  background-color: #303030 !important;
  color: white !important;
}

a {
  color: #289cff;
  text-decoration: none;
}
a:hover, a:focus {
  color: #4eadff;
}

.selected {
  background-color: #4eadff !important;
}
.selected mat-icon {
  color: white !important;
}
.selected a {
  color: #424242 !important;
  font-weight: bold !important;
}

.warn {
  color: #d1685f !important;
}

.mat-mdc-snack-bar-container {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.mat-mdc-snack-bar-container.no-action .mat-mdc-simple-snack-bar .mdc-snackbar__label {
  text-align: center;
}
.mat-mdc-snack-bar-container.alert-message .mat-mdc-snack-bar-label .mdc-snackbar__label {
  color: #d1685f;
}

.insight-toolbar {
  vertical-align: center;
  height: 50px;
  padding-left: 10px;
  background: #212121 !important;
  border: none;
}
.insight-toolbar .mat-toolbar {
  background: #424242 !important;
}

.toolbar-component {
  vertical-align: center;
  margin: 0 10px 0 10px;
}

.toolbar-text, .toolbar-text-secondary, .toolbar-text-bold {
  padding: 0 5px;
  font-size: 16px;
}

.toolbar-text-bold {
  font-weight: bold;
}

.toolbar-text-secondary {
  font-size: small;
  font-style: italic;
}

.toolbar-spacer {
  flex: 1 1 auto;
}

.info-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  background: #424242;
}
.info-bar .mat-divider.mat-divider-vertical {
  border-right-color: #028aff !important;
  margin-top: 6px;
  margin-bottom: 6px;
}

.info-bar-data {
  font-weight: bold;
  color: #4eadff;
}

.info-bar-component {
  font-size: 12pt;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  line-height: 40px;
  justify-content: center;
  white-space: nowrap;
  color: rgba(255, 255, 255, 0.7);
}

.bt-toggle .info-bar-toggle-label, .info-bar-component .info-bar-toggle-label {
  line-height: 32px;
}
.bt-toggle .mat-icon, .info-bar-component .mat-icon {
  padding: 12px 0 !important;
}
.bt-toggle .mat-button-toggle-label-content, .info-bar-component .mat-button-toggle-label-content {
  padding: 0 12px !important;
}
.bt-toggle .mat-button-toggle, .info-bar-component .mat-button-toggle {
  padding: 0;
  line-height: 32px !important;
}
.bt-toggle .mat-button-toggle.mat-button-toggle-checked, .info-bar-component .mat-button-toggle.mat-button-toggle-checked {
  color: white;
  background: #028aff;
}
.bt-toggle .mat-button-toggle.mat-button-toggle-disabled, .info-bar-component .mat-button-toggle.mat-button-toggle-disabled {
  background: rgba(255, 255, 255, 0.3);
}

.mat-vertical-stepper-header {
  padding: 24px 12px !important;
}

.mat-vertical-content-container {
  margin-left: 24px !important;
}

.mat-step-header .mat-step-icon-state-number {
  color: rgba(0, 0, 0, 0.87);
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #028aff;
  color: white;
}

.as-split-gutter {
  background-color: unset !important;
}

.bt-split-gutter {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.12) !important;
  position: relative;
}

.bt-split-gutter-icon {
  height: 100%;
  width: 100%;
  background-color: black;
  transition: opacity 0.3s;
  opacity: 0;
  position: absolute;
}
.dragged .bt-split-gutter-icon, .bt-split-gutter-icon:hover {
  opacity: 0.2;
  z-index: 100;
}
.as-horizontal .bt-split-gutter-icon {
  width: 17px;
  height: 100%;
  left: -8px;
  right: 8px;
}
.as-vertical .bt-split-gutter-icon {
  height: 17px;
  width: 100%;
  top: -8px;
  bottom: 8px;
  left: 0;
}

.analytics-nav-bar {
  flex-basis: 48px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.analytics-nav-bar .mat-mdc-tab-link {
  margin-right: 5px !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  font-weight: 400;
  height: 38px;
  min-width: 120px;
  padding: 0 12px 0 12px;
}
.analytics-nav-bar .mat-mdc-tab-link:hover, .analytics-nav-bar .mat-mdc-tab-link:focus {
  background-color: rgba(255, 255, 255, 0.04) !important;
}
.analytics-nav-bar .mat-mdc-tab-link.mat-mdc-tab-disabled {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(255, 255, 255, 0.5) !important;
}
.analytics-nav-bar .analytics-tab-nav-item-selected {
  font-weight: 500;
  background-color: #028aff !important;
}
.analytics-nav-bar .analytics-tab-nav-item-selected .mat-list-item-content {
  color: white;
}
.analytics-nav-bar .analytics-tab-nav-item-selected:hover, .analytics-nav-bar .analytics-tab-nav-item-selected:focus {
  background-color: #0177ff !important;
}

.analytics-nav {
  display: flex;
  flex-direction: row;
  margin: -10px;
  padding: 10px;
  overflow-y: hidden;
}

.analytics-toolbar {
  margin: 10px 10px 0 10px !important;
  display: flex !important;
  flex-direction: column !important;
  width: 320px !important;
  min-width: 320px !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding: 20px;
}

.analytics-toolbar-component {
  display: flex;
  width: 100%;
}
.analytics-toolbar-component .mat-button-toggle-group {
  width: 100%;
}
.analytics-toolbar-component .mat-button-toggle {
  padding: 0;
  width: 100%;
  color: white !important;
}
.analytics-toolbar-component .mat-button-toggle.mat-button-toggle-checked {
  color: white !important;
  background: #028aff;
}
.analytics-toolbar-component .mat-button-toggle.mat-button-toggle-disabled {
  background: rgba(255, 255, 255, 0.12);
}

.mat-mdc-menu-panel.mat-mdc-menu-panel.chart-info-menu {
  max-width: 550px !important;
  padding: 0 10px;
  text-align: justify;
}
.mat-mdc-menu-panel.mat-mdc-menu-panel.chart-info-menu span {
  margin: 20px;
  font-size: 10px;
}

.mat-mdc-card-title .chart-info-report {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 100;
  margin: 0 5px 0 !important;
  font-size: 12px;
  letter-spacing: -0.24px;
  background: #4eadff;
  color: white;
}

.toast-custom-message {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  background-color: rgba(78, 173, 255, 0.95) !important;
  color: white;
  pointer-events: auto;
}
.toast-custom-message a {
  vertical-align: middle;
  margin-left: 20px;
}

.dashboard-card {
  width: 100%;
  height: 100%;
}

.chart-no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 63px);
}
.chart-no-data__text {
  text-align: left;
  font-size: 16px;
}
.chart-no-data__main-text {
  font-size: 28px;
  font-weight: 700;
}
.chart-no-data__image {
  background-size: contain;
  background: url("/assets/marty.svg") no-repeat;
  width: 138px;
  height: 418px;
}

.dot {
  background: #028aff;
  width: 6px;
  height: 6px;
  border-radius: 2px;
}

.google-visualization-tooltip, .goog-tooltip {
  padding: 5px !important;
  text-align: left !important;
  font-size: small !important;
  margin: 0px !important;
  border: solid 1px rgba(255, 255, 255, 0.38) !important;
  box-shadow: none !important;
  background: #616161 !important;
  color: var(--mdc-plain-tooltip-supporting-text-color) !important;
  height: fit-content !important;
}

.goog-tooltip div {
  margin: 0px !important;
  border: none !important;
  font-size: small !important;
  color: var(--mdc-plain-tooltip-supporting-text-color) !important;
  background: #616161 !important;
}

.single-select-popup {
  min-width: 300px;
  max-height: 300px !important;
  overflow-x: hidden !important;
}
.single-select-popup .no-checkbox {
  padding-left: 0 !important;
}
.single-select-popup .no-checkbox .mat-pseudo-checkbox {
  display: none !important;
}

.multi-select-popup {
  min-width: 300px;
  transform: translateX(-300px) !important;
  position: absolute !important;
  bottom: 0;
  overflow-x: hidden !important;
}
.multi-select-popup .no-checkbox {
  padding-left: 0 !important;
}
.multi-select-popup .no-checkbox .mat-pseudo-checkbox {
  display: none !important;
}

.multi-select-popup .mat-mdc-option .mdc-list-item__primary-text {
  white-space: nowrap !important;
  padding-top: 0;
  font-size: small !important;
  font-weight: 500 !important;
}

.multi-select-popup .mat-mdc-option-active {
  background: rgba(0, 0, 0, 0.04) !important;
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
}
.mat-datepicker-content .mat-calendar .datepicker-message {
  padding: 8px;
}

.analytics-content {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
.analytics-content__amount {
  position: absolute;
  top: 0px;
  right: -30px;
  min-width: 10px;
  max-height: 10px;
  padding: 5px;
  background-color: #d1685f;
  color: white;
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  border-radius: 0.75rem;
  z-index: 1;
}
.searchBar input {
  background: unset !important;
  color: white;
}

.elements {
  padding: 5px;
}
.elements__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 0;
  padding: 5px;
  margin: 5px;
  border: 1px solid rgba(255, 255, 255, 0.38);
  border-radius: 5px;
}
.elements__item mat-icon {
  margin-right: 5px;
  cursor: move;
}
.elements__item input {
  height: 18px;
  width: 38px;
}
.elements__button {
  display: flex;
  margin-bottom: 5px;
  justify-content: center;
  align-items: flex-end;
}
.elements__button__delete {
  margin-left: 10px;
}
.elements__main_item {
  margin-bottom: 15px;
}

.input-err {
  outline-color: #d1685f;
}

.lists {
  display: flex;
  justify-content: space-between;
}
.lists__section {
  width: 49%;
}
.lists__section mat-list-item {
  height: 32px !important;
  padding: 2px;
  margin-bottom: 5px;
}
.lists__item {
  display: flex;
  align-items: center;
  height: 24px;
  border: 1px solid rgba(255, 255, 255, 0.38);
  border-radius: 3px;
  padding: 3px;
}
.lists__item button {
  margin-left: auto;
}

.path-list {
  background: #424242;
}

.path-table {
  background: #424242;
}

.control-color {
  color: !important;
}

.base-scan-container {
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  overflow: hidden;
}

.warning {
  background: #d1685f;
  color: #fffcfc;
}

.instruction-header {
  color: #4eadff;
}

.instruction {
  color: rgba(255, 255, 255, 0.7);
}

.tag-tooltip {
  background: #424242;
  border: 2px solid #028aff;
}

.tag-tooltip-component {
  color: #028aff;
}

.stepper-toggle-panel .mat-button-toggle {
  color: #028aff;
}
.stepper-toggle-panel .mat-button-toggle.mat-button-toggle-checked {
  color: #424242;
  background: #028aff;
}
.stepper-toggle-panel .mat-button-toggle.mat-button-toggle-disabled {
  background: rgba(255, 255, 255, 0.12);
}

.tag-disabled {
  color: #4eadff;
}

.outer-segment {
  background: #4eadff;
}

.inner-segment {
  background: #424242;
  color: #4eadff;
}

.scan-event-container {
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  overflow: hidden;
}

.scan-event-content-container {
  background-color: #424242;
}

.unavailable-tag-image {
  background-color: rgba(255, 255, 255, 0.12);
}

.image-unavailable-icon {
  fill: #424242 !important;
}

.issue-disabled {
  color: rgba(255, 255, 255, 0.5);
}

.total-issues-chip {
  background-color: #4eadff !important;
}

.unreviewed-chip {
  background-color: #4eadff !important;
}

.missing-image-message {
  color: rgba(255, 255, 255, 0.5);
}

.filter-chip, .viewport-filter-chip, .incorrect-product-filter-chip, .hole-filter-chip, .sale-price-filter-chip, .price-filter-chip {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
.filter-chip.mat-mdc-standard-chip:not(.mat-mdc-chip-selected), .mat-mdc-standard-chip.viewport-filter-chip:not(.mat-mdc-chip-selected), .mat-mdc-standard-chip.incorrect-product-filter-chip:not(.mat-mdc-chip-selected), .mat-mdc-standard-chip.hole-filter-chip:not(.mat-mdc-chip-selected), .mat-mdc-standard-chip.sale-price-filter-chip:not(.mat-mdc-chip-selected), .mat-mdc-standard-chip.price-filter-chip:not(.mat-mdc-chip-selected), .filter-chip .mat-mdc-button, .viewport-filter-chip .mat-mdc-button, .incorrect-product-filter-chip .mat-mdc-button, .hole-filter-chip .mat-mdc-button, .sale-price-filter-chip .mat-mdc-button, .price-filter-chip .mat-mdc-button {
  background-color: rgba(255, 255, 255, 0.3) !important;
  --mdc-chip-label-text-color: mat.m2-get-color-from-palette($primary, white) !important;
  --mdc-chip-with-icon-icon-color: mat.m2-get-color-from-palette($primary, white) !important;
  color: white;
}

.price-filter-chip.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
  background-color: #4D8316 !important;
}

.sale-price-filter-chip.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
  background-color: #FFCB24 !important;
}

.hole-filter-chip.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
  background-color: #ff5722 !important;
}

.incorrect-product-filter-chip.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
  background-color: #29D6FF !important;
}

.viewport-filter-chip.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
  background-color: #DB7093 !important;
}

.viewport-list {
  background: #424242;
}
.viewport-list .selected {
  background-color: rgba(255, 255, 255, 0.04) !important;
}

.viewport-table {
  background: #424242;
}

.topstock_icon svg {
  width: 20px !important;
  height: 20px;
}

.topstock_icon svg path {
  fill: #028aff !important;
}

.mat-column-sortOrdinal {
  color: rgba(255, 255, 255, 0.7);
}

.heading-sub-text {
  color: rgba(255, 255, 255, 0.7);
}

.scan-event-list {
  background: #424242;
}

.audit-container {
  background-color: #424242;
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  overflow: hidden;
}

.no-product-image-text {
  color: #4eadff;
}

.instruction-div {
  color: #028aff;
  background-color: #424242;
}

.section-heading {
  background-color: #424242;
}

.audit-control-panel {
  background-color: #424242;
}

.confirmed-issues {
  color: rgba(255, 255, 255, 0.5);
}

.step-heading {
  color: #028aff;
}

:host ::ng-deep .mat-step-header .mat-step-icon {
  background-color: #028aff;
}

mat-button-toggle.align-button {
  color: #028aff;
}
mat-button-toggle.align-button.mat-button-toggle-checked {
  background: #028aff;
  color: white;
}
mat-button-toggle.align-button.mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.5);
  background: #424242;
}

.region-heading {
  color: rgba(255, 255, 255, 0.7);
}

.urgent {
  color: #d1685f !important;
}

.no-realograms {
  color: rgba(255, 255, 255, 0.5);
}

.swatch {
  width: 175px;
  height: 75px;
}

.p50 {
  background: #e1f1ff;
  color: rgba(0, 0, 0, 0.87);
}

.p100 {
  background: #b3dcff;
  color: rgba(0, 0, 0, 0.87);
}

.p200 {
  background: #81c5ff;
  color: rgba(0, 0, 0, 0.87);
}

.p300 {
  background: #4eadff;
  color: rgba(0, 0, 0, 0.87);
}

.p400 {
  background: #289cff;
  color: rgba(0, 0, 0, 0.87);
}

.p500 {
  background: #028aff;
  color: white;
}

.p600 {
  background: #0282ff;
  color: white;
}

.p700 {
  background: #0177ff;
  color: white;
}

.p800 {
  background: #016dff;
  color: white;
}

.p900 {
  background: #015aff;
  color: white;
}

.pA100 {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.pA200 {
  background: #f2f6ff;
  color: rgba(0, 0, 0, 0.87);
}

.pA400 {
  background: #bfd2ff;
  color: rgba(0, 0, 0, 0.87);
}

.pA700 {
  background: #a6c0ff;
  color: rgba(0, 0, 0, 0.87);
}

.a50 {
  background: #e1f1ff;
  color: rgba(0, 0, 0, 0.87);
}

.a100 {
  background: #b3dcff;
  color: rgba(0, 0, 0, 0.87);
}

.a200 {
  background: #81c5ff;
  color: rgba(0, 0, 0, 0.87);
}

.a300 {
  background: #4eadff;
  color: rgba(0, 0, 0, 0.87);
}

.a400 {
  background: #289cff;
  color: rgba(0, 0, 0, 0.87);
}

.a500 {
  background: #028aff;
  color: white;
}

.a600 {
  background: #0282ff;
  color: white;
}

.a700 {
  background: #0177ff;
  color: white;
}

.a800 {
  background: #016dff;
  color: white;
}

.a900 {
  background: #015aff;
  color: white;
}

.aA100 {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.aA200 {
  background: #f2f6ff;
  color: rgba(0, 0, 0, 0.87);
}

.aA400 {
  background: #bfd2ff;
  color: rgba(0, 0, 0, 0.87);
}

.aA700 {
  background: #a6c0ff;
  color: rgba(0, 0, 0, 0.87);
}

.w50 {
  background: #f9edec;
  color: rgba(0, 0, 0, 0.87);
}

.w100 {
  background: #f1d2cf;
  color: rgba(0, 0, 0, 0.87);
}

.w200 {
  background: #e8b4af;
  color: rgba(0, 0, 0, 0.87);
}

.w300 {
  background: #df958f;
  color: rgba(0, 0, 0, 0.87);
}

.w400 {
  background: #d87f77;
  color: rgba(0, 0, 0, 0.87);
}

.w500 {
  background: #d1685f;
  color: white;
}

.w600 {
  background: #cc6057;
  color: white;
}

.w700 {
  background: #c6554d;
  color: white;
}

.w800 {
  background: #c04b43;
  color: white;
}

.w900 {
  background: #b53a32;
  color: white;
}

.wA100 {
  background: #fffcfc;
  color: rgba(0, 0, 0, 0.87);
}

.wA200 {
  background: #ffcbc9;
  color: rgba(0, 0, 0, 0.87);
}

.wA400 {
  background: #ff9b96;
  color: rgba(0, 0, 0, 0.87);
}

.wA700 {
  background: #ff837c;
  color: rgba(0, 0, 0, 0.87);
}

.background-status-bar {
  background: black;
}

.background-app-bar {
  background: #212121;
}

.background-background {
  background: #303030;
}

.background-hover {
  background: rgba(255, 255, 255, 0.04);
}

.background-card {
  background: #424242;
}

.background-dialog {
  background: #424242;
}

.background-disabled-button {
  background: rgba(255, 255, 255, 0.12);
}

.background-raised-button {
  background: #424242;
}

.background-focused-button {
  background: rgba(255, 255, 255, 0.12);
}

.background-selected-button {
  background: #212121;
}

.background-selected-disabled-button {
  background: #424242;
}

.background-disabled-button-toggle {
  background: black;
}

.background-unselected-chip {
  background: #616161;
}

.background-disabled-list-option {
  background: rgba(255, 255, 255, 0.12);
}

.background-tooltip {
  background: #616161;
}

.foreground-base {
  background: white;
}

.foreground-divider {
  background: rgba(255, 255, 255, 0.12);
}

.foreground-dividers {
  background: rgba(255, 255, 255, 0.12);
}

.foreground-disabled {
  background: rgba(255, 255, 255, 0.5);
}

.foreground-disabled-button {
  background: rgba(255, 255, 255, 0.3);
}

.foreground-disabled-text {
  background: rgba(255, 255, 255, 0.5);
}

.foreground-elevation {
  background: black;
}

.foreground-hint-text {
  background: rgba(255, 255, 255, 0.5);
}

.foreground-secondary-text {
  background: rgba(255, 255, 255, 0.7);
}

.foreground-icon {
  background: white;
}

.foreground-icons {
  background: white;
}

.foreground-text {
  background: white;
}

.foreground-slider-min {
  background: white;
}

.foreground-slider-off {
  background: rgba(255, 255, 255, 0.3);
}

.foreground-slider-off-active {
  background: rgba(255, 255, 255, 0.3);
}

.duration {
  color: rgba(255, 255, 255, 0.7);
}

.issue-resolution-unknown-icon {
  color: rgba(255, 255, 255, 0.5);
}

.missing-image-svg {
  width: 75%;
  height: 75%;
  fill: rgba(255, 255, 255, 0.5);
}

.viewport-thumbnail-selected {
  background-color: #028aff;
}

.insightImageContainer {
  background: rgba(255, 255, 255, 0.12);
}

.play-execution-nav-container {
  background-color: #0177ff;
}
.play-execution-nav-container .mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label {
  color: white !important;
  opacity: 1;
}
.play-execution-nav-container .mat-mdc-tab-link .mdc-tab__text-label {
  color: white !important;
  opacity: 0.6;
}
.play-execution-nav-container .mdc-tab-indicator__content--underline {
  border-color: white !important;
}

.issue-disabled {
  color: rgba(255, 255, 255, 0.3) !important;
}

.urgent {
  color: #d1685f;
}

.search-result-subtext {
  color: rgba(255, 255, 255, 0.7);
}

.insight-review-chip.mat-mdc-chip .mdc-evolution-chip__icon--primary {
  color: #028aff !important;
}
.insight-review-chip.mat-mdc-chip.mat-mdc-standard-chip, .insight-review-chip.mat-mdc-chip .mat-mdc-button {
  background-color: white;
  color: #028aff;
}
.insight-review-chip.mat-mdc-chip .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
  color: #028aff;
}

.insight-review-urgent-chip.mat-mdc-chip .mdc-evolution-chip__icon--primary {
  color: #c6554d !important;
}
.insight-review-urgent-chip.mat-mdc-chip .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
  color: #c6554d;
}
.insight-review-urgent-chip.mat-mdc-chip.mat-mdc-standard-chip, .insight-review-urgent-chip.mat-mdc-chip .mat-mdc-button, .insight-review-urgent-chip.mat-mdc-chip .mdc-button__label {
  background-color: white;
  color: #c6554d !important;
}

button.reviews-button {
  background-color: white !important;
}
button.reviews-button .mat-mdc-button, button.reviews-button .mdc-button__label {
  color: #028aff !important;
}

button.reviews-button-urgent {
  background-color: white !important;
}
button.reviews-button-urgent .mat-mdc-button, button.reviews-button-urgent .mdc-button__label {
  color: #c6554d !important;
}

.review-chip-icon {
  color: #0177ff;
}

.review-chip-urgent-icon {
  color: #c6554d;
}

.insight-review-org-selected.mdc-list-item--selected span.mdc-list-item__primary-text {
  color: #028aff !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #028aff !important;
}

.table-div {
  background: #424242;
}

.container {
  color: white;
}

.option-list {
  color: white;
}

.title {
  color: rgba(255, 255, 255, 0.7);
}

:host ::ng-deep .cdk-overlay-pane {
  background: green !important;
  padding: 45px;
}

.title {
  color: rgba(255, 255, 255, 0.7);
}

.multi-select-button {
  color: rgba(255, 255, 255, 0.7);
}

.dot {
  background: #13A1DA;
}

.review-age-chip.mat-mdc-chip .mdc-evolution-chip__icon--primary, .review-age-chip.mat-mdc-chip .mdc-evolution-chip__icon--trailing {
  color: #028aff !important;
}
.review-age-chip.mat-mdc-chip.mat-mdc-standard-chip, .review-age-chip.mat-mdc-chip .mat-mdc-button {
  background-color: white;
  color: #028aff;
}
.review-age-chip.mat-mdc-chip .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
  color: #028aff;
}

.review-age-urgent-chip.mat-mdc-chip .mdc-evolution-chip__icon--primary, .review-age-urgent-chip.mat-mdc-chip .mdc-evolution-chip__icon--trailing {
  color: #c6554d !important;
}
.review-age-urgent-chip.mat-mdc-chip .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
  color: #c6554d;
}
.review-age-urgent-chip.mat-mdc-chip.mat-mdc-standard-chip, .review-age-urgent-chip.mat-mdc-chip .mat-mdc-button {
  background-color: white;
  color: #c6554d;
}

.inspect-review-chip.mat-mdc-chip .mdc-evolution-chip__icon--primary {
  color: #028aff !important;
}
.inspect-review-chip.mat-mdc-chip.mat-mdc-standard-chip, .inspect-review-chip.mat-mdc-chip .mat-mdc-button {
  background-color: white;
  color: #028aff;
}
.inspect-review-chip.mat-mdc-chip .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
  color: #028aff;
}

.inspect-review-urgent-chip.mat-mdc-chip .mdc-evolution-chip__icon--primary {
  color: #c6554d !important;
}
.inspect-review-urgent-chip.mat-mdc-chip .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
  color: #c6554d;
}
.inspect-review-urgent-chip.mat-mdc-chip.mat-mdc-standard-chip, .inspect-review-urgent-chip.mat-mdc-chip .mat-mdc-button {
  background-color: white;
  color: #c6554d;
}

.mur-roster.mat-mdc-chip .mdc-evolution-chip__icon--primary {
  color: #028aff !important;
}
.mur-roster.mat-mdc-chip.mat-mdc-standard-chip, .mur-roster.mat-mdc-chip .mat-mdc-button {
  background-color: white;
  color: #028aff;
}
.mur-roster.mat-mdc-chip .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
  color: #028aff;
}

.mur-roster-crowded.mat-mdc-chip .mdc-evolution-chip__icon--primary {
  color: #c6554d !important;
}
.mur-roster-crowded.mat-mdc-chip .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
  color: #c6554d;
}
.mur-roster-crowded.mat-mdc-chip.mat-mdc-standard-chip, .mur-roster-crowded.mat-mdc-chip .mat-mdc-button, .mur-roster-crowded.mat-mdc-chip .mdc-button__label {
  background-color: white;
  color: #c6554d !important;
}

body.bt-fullscreen-map {
  overflow: hidden !important;
}

.map-control .mat-button-toggle {
  color: white;
}
.map-control .mat-button-toggle.mat-button-toggle-checked {
  color: #4eadff;
}

.map-sidenav-container .collapse-button {
  background-color: #424242;
}
.map-sidenav-container .collapse-icon {
  color: white;
}
.map-sidenav-container .mat-drawer .mat-toolbar {
  background-color: #028aff;
}

nav.bt-navbar .mat-mdc-button .mat-mdc-button-persistent-ripple::before,
nav.bt-navbar .mat-mdc-button.active .mat-mdc-button-persistent-ripple::before {
  background-color: white;
}
nav.bt-navbar .mdc-icon-button .mat-mdc-button-persistent-ripple::before,
nav.bt-navbar .mdc-icon-button.active .mat-mdc-button-persistent-ripple::before {
  background-color: white;
}
nav.bt-navbar .mdc-text-field .mdc-notched-outline > * {
  border-color: rgba(255, 255, 255, 0.54) !important;
}
nav.bt-navbar .mat-mdc-form-field.mat-accent {
  --mat-select-enabled-arrow-color: rgb(255 255 255 / 54%);
}
nav.bt-navbar .mdc-button__label {
  color: white;
}
nav.bt-navbar .mat-mdc-select-value {
  color: white;
}
nav.bt-navbar a:hover, nav.bt-navbar a:focus {
  color: white;
}

.play-thumb-header {
  color: white;
}