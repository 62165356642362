@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    .viewport-thumbnail-selected {
      background-color: mat.get-theme-color($theme, primary);
    }
  } @else {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    $background: map.get($theme, background);
    $foreground: map.get($theme, foreground);

    .viewport-thumbnail-selected {
      background-color: mat.m2-get-color-from-palette($primary);
    }
  }
}

