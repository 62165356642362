@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    .play-thumb-header {
      color: mat.get-theme-color($theme, on-primary);
    }

  } @else {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    $background: map.get($theme, background);
    $foreground: map.get($theme, foreground);

    .play-thumb-header {
      color: mat.m2-get-color-from-palette($primary, A100);
    }
  }
}
