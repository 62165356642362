@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .analytics-content {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;

    &__amount {
      position: absolute;
      top: 0px;
      right: -30px;
      min-width: 10px;
      max-height: 10px;
      padding: 5px;
      background-color: mat.m2-get-color-from-palette($warn);
      color: mat.m2-get-contrast-color-from-palette($warn, 500);
      font-size: 11px;
      font-weight: bold;
      line-height: 1;
      text-align: center;
      border-radius: .75rem;
      z-index: 1;
    }

    &__amount_text {

    }
  }

  .searchBar {
    input {
      background: unset !important;
      color: mat.m2-get-color-from-palette($foreground, text);
    }
  }
}
