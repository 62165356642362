@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .lists {
    display: flex;
    justify-content: space-between;

    &__section {
      width: 49%;

      mat-list-item {
        height: 32px !important;
        padding: 2px;
        margin-bottom: 5px;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      height: 24px;
      border: 1px solid mat.m2-get-color-from-palette($primary, field-outline);
      border-radius: 3px;
      padding: 3px;

      button {
        margin-left: auto;
      }
    }
  }
}
