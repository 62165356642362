@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    .bt-map-container {
      background-color: mat.get-theme-color($theme, background); //mat.m2-get-color-from-palette($background);
    }

    body.bt-fullscreen-map {
      @extend .bt-map-container;
      overflow: hidden !important;
    }

    .map-control {
      .mat-button-toggle {
        color: mat.get-theme-color($theme, on-background);

        &.mat-button-toggle-checked {
          color: mat.get-theme-color($theme, secondary);
        }
      }
    }

  } @else {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    $background: map.get($theme, background);
    $foreground: map.get($theme, foreground);

    .bt-map-container {
      background-color: mat.m2-get-color-from-palette($background);
    }

    body.bt-fullscreen-map {
      @extend .bt-map-container;
      overflow: hidden !important;
    }

    .map-control {
      .mat-button-toggle {
        color: mat.m2-get-color-from-palette($foreground, base);

        &.mat-button-toggle-checked {
          color: mat.m2-get-color-from-palette($accent);
        }
      }
    }
  }
}
