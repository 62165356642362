@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .issue-resolution-unknown-icon {
    color: mat.m2-get-color-from-palette($foreground, disabled);
  }

  .missing-image-svg {
    width: 75%;
    height: 75%;
    fill: mat.m2-get-color-from-palette($foreground, disabled);
  }

}
