@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .heading-sub-text {
    color: mat.m2-get-color-from-palette($foreground, secondary-text);
  }

  .scan-event-list {
    background: mat.m2-get-color-from-palette($background, dialog);
  }
}
