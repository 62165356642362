@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    .play-execution-nav-container {
      .mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label {
        color: mat.get-theme-color($theme, on-primary) !important;
        opacity: 1.0;
      }

      .mat-mdc-tab-link .mdc-tab__text-label {
        color: mat.get-theme-color($theme, on-primary) !important;
        opacity: .6;
      }

      .mdc-tab-indicator__content--underline {
        border-color: mat.get-theme-color($theme, on-primary) !important;
      }

      background-color: mat.get-theme-color($theme, primary, 70);
    }
  } @else {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    $background: map.get($theme, background);
    $foreground: map.get($theme, foreground);

    .play-execution-nav-container {
      .mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label {
        color: mat.m2-get-contrast-color-from-palette($primary, 700) !important;
        opacity: 1.0;
      }

      .mat-mdc-tab-link .mdc-tab__text-label {
        color: mat.m2-get-contrast-color-from-palette($primary, 700) !important;
        opacity: .6;
      }

      .mdc-tab-indicator__content--underline {
        border-color: mat.m2-get-contrast-color-from-palette($primary, 700) !important;
      }

      background-color: mat.m2-get-color-from-palette($primary, 700);
    }
  }
}

