@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .audit-container {
    background-color: mat.m2-get-color-from-palette($background, card);
    border: 1px solid mat.m2-get-color-from-palette($foreground, divider);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    overflow: hidden;
  }

  .no-product-image-text {
    color: mat.m2-get-color-from-palette($accent);
  }
}
