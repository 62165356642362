@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    .table-div {
      background: mat.get-theme-color($theme, surface-container-high);
    }
  } @else {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    $background: map.get($theme, background);
    $foreground: map.get($theme, foreground);

    .table-div {
      background: mat.m2-get-color-from-palette($background, dialog);
    }
  }
}
