@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    .insight-review-chip.mat-mdc-chip {
      .mdc-evolution-chip__icon--primary {
        color: mat.get-theme-color($theme, primary) !important;
      }

      &.mat-mdc-standard-chip, .mat-mdc-button {
        background-color: white;
        color: mat.get-theme-color($theme, primary);
      }

      .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
        color: mat.get-theme-color($theme, primary);
      }
    }

    .insight-review-urgent-chip.mat-mdc-chip {
      .mdc-evolution-chip__icon--primary {
        color: mat.get-theme-color($theme, error, 70) !important;
      }

      .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
        color: mat.get-theme-color($theme, error, 70);
      }

      &.mat-mdc-standard-chip, .mat-mdc-button, .mdc-button__label {
        background-color: white;
        color: mat.get-theme-color($theme, error, 70) !important;
      }
    }

    button.reviews-button {
      background-color: white !important;

      .mat-mdc-button, .mdc-button__label {
        color: mat.get-theme-color($theme, primary) !important;
      }
    }

    button.reviews-button-urgent {
      background-color: white !important;

      .mat-mdc-button, .mdc-button__label {
        color: mat.get-theme-color($theme, error, 70) !important;
      }
    }

    .review-chip-icon {
      color: mat.get-theme-color($theme, primary, 70);
    }

    .review-chip-urgent-icon {
      color: mat.get-theme-color($theme, error, 70);
    }

    .insight-review-org-selected.mdc-list-item--selected span.mdc-list-item__primary-text {
      color: mat.get-theme-color($theme, primary) !important;
    }

    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      color: mat.get-theme-color($theme, primary) !important;
    }

  } @else {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    $background: map.get($theme, background);
    $foreground: map.get($theme, foreground);

    .insight-review-chip.mat-mdc-chip {
      .mdc-evolution-chip__icon--primary {
        color: mat.m2-get-color-from-palette($primary) !important;
      }

      &.mat-mdc-standard-chip, .mat-mdc-button {
        background-color: white;
        color: mat.m2-get-color-from-palette($primary);
      }

      .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
        color: mat.m2-get-color-from-palette($primary);
      }
    }

    .insight-review-urgent-chip.mat-mdc-chip {
      .mdc-evolution-chip__icon--primary {
        color: mat.m2-get-color-from-palette($warn, darker) !important;
      }

      .mat-mdc-chip-action-label.mdc-evolution-chip__text-label {
        color: mat.m2-get-color-from-palette($warn, darker);
      }

      &.mat-mdc-standard-chip, .mat-mdc-button, .mdc-button__label {
        background-color: white;
        color: mat.m2-get-color-from-palette($warn, darker) !important;
      }
    }

    button.reviews-button {
      background-color: white !important;

      .mat-mdc-button, .mdc-button__label {
        color: mat.m2-get-color-from-palette($primary) !important;
      }
    }

    button.reviews-button-urgent {
      background-color: white !important;

      .mat-mdc-button, .mdc-button__label {
        color: mat.m2-get-color-from-palette($warn, darker) !important;
      }
    }

    .review-chip-icon {
      color: mat.m2-get-color-from-palette($primary, darker);
    }

    .review-chip-urgent-icon {
      color: mat.m2-get-color-from-palette($warn, darker);
    }

    .insight-review-org-selected.mdc-list-item--selected span.mdc-list-item__primary-text {
      color: mat.m2-get-color-from-palette($primary) !important;
    }

    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
      color: mat.m2-get-color-from-palette($primary) !important;
    }
  }
}
