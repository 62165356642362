@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    nav.bt-navbar {
      .mat-mdc-button .mat-mdc-button-persistent-ripple::before,
      .mat-mdc-button.active .mat-mdc-button-persistent-ripple::before {
        background-color: white; // TODO: we need a new named style for this? mat.m2-get-color-from-palette($primary, white);
      }

      .mdc-icon-button .mat-mdc-button-persistent-ripple::before,
      .mdc-icon-button.active .mat-mdc-button-persistent-ripple::before {
        background-color: white;
      }

      .mdc-text-field {
        .mdc-notched-outline > * {
          border-color: rgb(255 255 255 / 54%) !important;
        }
      }

      .mat-mdc-form-field.mat-accent {
        --mat-select-enabled-arrow-color: rgb(255 255 255 / 54%);
      }

      .mdc-button__label {
        color: white;
      }

      .mat-mdc-select-value {
        color: white;
      }

      a:hover, a:focus {
        color: mat.get-theme-color($theme, on-background);
      }
    }
  } @else {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    $background: map.get($theme, background);
    $foreground: map.get($theme, foreground);

    nav.bt-navbar {
      .mat-mdc-button .mat-mdc-button-persistent-ripple::before,
      .mat-mdc-button.active .mat-mdc-button-persistent-ripple::before {
        background-color: mat.m2-get-color-from-palette($primary, white);
      }

      .mdc-icon-button .mat-mdc-button-persistent-ripple::before,
      .mdc-icon-button.active .mat-mdc-button-persistent-ripple::before {
        background-color: mat.m2-get-color-from-palette($primary, white);
      }

      .mdc-text-field {
        .mdc-notched-outline > * {
          border-color: rgb(255 255 255 / 54%) !important;
        }
      }

      .mat-mdc-form-field.mat-accent {
        --mat-select-enabled-arrow-color: rgb(255 255 255 / 54%);
      }

      .mdc-button__label {
        color: white;
      }

      .mat-mdc-select-value {
        color: white;
      }

      a:hover, a:focus {
        color: mat.m2-get-color-from-palette($foreground, text);
      }
    }
  }
}

.badger-nav-bar {
  background: linear-gradient(310deg, rgba(31, 61, 96, 1) 0%, rgba(19, 37, 58, 1) 100%) !important;
}
