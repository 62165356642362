@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  @if (mat.get-theme-version($theme) == 1) {
    :host ::ng-deep .cdk-overlay-pane {
      //TODO fix, why green? this is only used in analytics
      background: green !important;
      padding: 45px;
    }

    .title {
      color: mat.get-theme-color($theme, on-surface);
    }

    .multi-select-button {
      color: mat.get-theme-color($theme, on-surface);
    }

    .dot {
      //TODO fix, should be a defined color, used in analytics only
      background: #13A1DA;
    }

  } @else {
    $primary: map.get($theme, primary);
    $accent: map.get($theme, accent);
    $warn: map.get($theme, warn);
    $background: map.get($theme, background);
    $foreground: map.get($theme, foreground);

    :host ::ng-deep .cdk-overlay-pane {
      //TODO fix, why green? this is only used in analytics
      background: green !important;
      padding: 45px;
    }

    .title {
      color: mat.m2-get-color-from-palette($foreground, secondary-text);
    }

    .multi-select-button {
      color: mat.m2-get-color-from-palette($foreground, secondary-text);
    }

    .dot {
      //TODO fix, should be a defined color, used in analytics only
      background: #13A1DA;
    }
  }
}
