@use 'sass:map';
@use '@angular/material' as mat;

@use "solutions";

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .scan-event-container {
    border: 1px solid mat.m2-get-color-from-palette($foreground, divider);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    overflow: hidden;
  }

  .scan-event-content-container {
    background-color: mat.m2-get-color-from-palette($background, card);
  }

  .unavailable-tag-image {
    background-color: mat.m2-get-color-from-palette($foreground, dividers);
  }

  .image-unavailable-icon {
    fill: mat.m2-get-color-from-palette($background, dialog) !important;
  }

  .issue-disabled {
    color: mat.m2-get-color-from-palette($foreground, disabled-text);
  }

  .total-issues-chip {
    background-color: mat.m2-get-color-from-palette($accent) !important;
  }

  .unreviewed-chip {
    background-color: mat.m2-get-color-from-palette($accent) !important;
  }

  .missing-image-message {
    color: mat.m2-get-color-from-palette($foreground, disabled-text);
  }

  .filter-chip {
    background-color: mat.m2-get-color-from-palette($foreground, slider-off) !important;

    &.mat-mdc-standard-chip:not(.mat-mdc-chip-selected), .mat-mdc-button {
      background-color: mat.m2-get-color-from-palette($foreground, slider-off) !important;
      --mdc-chip-label-text-color: mat.m2-get-color-from-palette($primary, white) !important;
      --mdc-chip-with-icon-icon-color: mat.m2-get-color-from-palette($primary, white) !important;

      color: mat.m2-get-color-from-palette($primary, white);
    }
  }

  .price-filter-chip {
    @extend .filter-chip;

    &.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
      background-color: #{solutions.$price} !important;
    }
  }

  .sale-price-filter-chip {
    @extend .filter-chip;

    &.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
      background-color: #{solutions.$sale-price} !important;
    }
  }

  .hole-filter-chip {
    @extend .filter-chip;

    &.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
      background-color: #{solutions.$out-of-stock} !important;
    }
  }

  .incorrect-product-filter-chip {
    @extend .filter-chip;

    &.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
      background-color: #{solutions.$incorrect-product} !important;
    }
  }

  .viewport-filter-chip {
    @extend .filter-chip;

    &.mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
      background-color: #{solutions.$viewport-issue} !important;
    }
  }
}
