@use 'sass:map';
@use '@angular/material' as mat;

@use 'ngx-toastr/toastr';

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .analytics-nav-bar {
    flex-basis: 48px;
    margin-top: 5px;
    margin-bottom: 10px;

    .mat-mdc-tab-link {
      margin-right: 5px !important;
      border-radius: 5px !important;
      opacity: 1 !important;
      font-weight: 400;
      height: 38px;
      min-width: 120px;
      padding: 0 12px 0 12px;

      &:hover, &:focus {
        background-color: mat.m2-get-color-from-palette($background, hover) !important;
      }

      &.mat-mdc-tab-disabled {
        background-color: mat.m2-get-color-from-palette($background, disabled-button) !important;
        color: mat.m2-get-color-from-palette($foreground, disabled-text) !important;
      }
    }

    .analytics-tab-nav-item-selected {
      font-weight: 500;
      background-color: mat.m2-get-color-from-palette($primary) !important;

      .mat-list-item-content {
        color: mat.m2-get-contrast-color-from-palette($primary, 500);
      }

      &:hover, &:focus {
        background-color: mat.m2-get-color-from-palette($primary, 700) !important;
      }
    }
  }

  .analytics-nav {
    display: flex;
    flex-direction: row;
    margin: -10px;
    padding: 10px;
    overflow-y: hidden;
  }

  .analytics-toolbar {
    margin: 10px 10px 0 10px !important;
    display: flex !important;
    flex-direction: column !important;
    width: 320px !important;
    min-width: 320px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    padding: 20px;
  }

  .analytics-toolbar-component {
    display: flex;
    width: 100%;

    .mat-button-toggle-group {
      width: 100%;
    }

    .mat-button-toggle {
      padding: 0;
      width: 100%;
      color: mat.m2-get-color-from-palette($foreground, text) !important;

      &.mat-button-toggle-checked {
        color: mat.m2-get-contrast-color-from-palette($primary, 500) !important;
        background: mat.m2-get-color-from-palette($primary);
      }

      &.mat-button-toggle-disabled {
        background: mat.m2-get-color-from-palette($background, disabled-button);
      }
    }
  }

  .mat-mdc-menu-panel.mat-mdc-menu-panel.chart-info-menu {
    max-width: 550px !important;
    padding: 0 10px;
    text-align: justify;

    span {
      margin: 20px;
      font-size: 10px;
    }
  }

  .mat-mdc-card-title .chart-info-report {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 100;
    margin: 0 5px 0 !important;
    font-size: 12px;
    letter-spacing: -0.24px;
    background: mat.m2-get-color-from-palette($accent);
    color: mat.m2-get-contrast-color-from-palette($accent, 500);
  }

  .toast-custom-message {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    border-radius: 3px 3px 3px 3px;
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 24px;
    background-color: mat.m2-get-color-from-palette($accent, .95) !important;
    color: mat.m2-get-contrast-color-from-palette($accent, 500);
    pointer-events: auto;

    a {
      vertical-align: middle;
      margin-left: 20px;
    }
  }

  .dashboard-card {
    width: 100%;
    height: 100%;
  }

  .chart-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 63px);

    &__text {
      text-align: left;
      font-size: 16px;
    }

    &__main-text {
      font-size: 28px;
      font-weight: 700;
    }

    &__image {
      background-size: contain;
      background: url('/assets/marty.svg') no-repeat;
      width: 138px;
      height: 418px;
    }
  }

  .dot {
    background: mat.m2-get-color-from-palette($primary);
    width: 6px;
    height: 6px;
    border-radius: 2px;
  }

  .google-visualization-tooltip, .goog-tooltip {
    padding: 5px !important;
    text-align: left !important;
    font-size: small !important;
    margin: 0px !important;
    border: solid 1px mat.m2-get-color-from-palette($primary, field-outline) !important;
    box-shadow: none !important;
    background: mat.m2-get-color-from-palette($background, tooltip) !important;
    color: var(--mdc-plain-tooltip-supporting-text-color) !important;
    height: fit-content !important;
  }

  .goog-tooltip div {
    margin: 0px !important;
    border: none !important;
    font-size: small !important;
    color: var(--mdc-plain-tooltip-supporting-text-color) !important;
    background: mat.m2-get-color-from-palette($background, tooltip) !important;
  }

  .single-select-popup {
    min-width: 300px;
    max-height: 300px !important;
    overflow-x: hidden !important;

    .no-checkbox {
      padding-left: 0 !important;

      .mat-pseudo-checkbox {
        display: none !important;
      }
    }
  }

  .multi-select-popup {
    min-width: 300px;
    transform: translateX(-300px) !important;
    position: absolute !important;
    bottom: 0;
    overflow-x: hidden !important;

    .no-checkbox {
      padding-left: 0 !important;

      .mat-pseudo-checkbox {
        display: none !important;
      }
    }
  }

  .multi-select-popup .mat-mdc-option .mdc-list-item {
    &__primary-text {
      white-space: nowrap !important;
      padding-top: 0;
      font-size: small !important;
      font-weight: 500 !important;
    }
  }

  // to force item highlight if it is checked (mat 15.2.1)
  .multi-select-popup .mat-mdc-option-active {
    background: rgba(0, 0, 0, .04) !important;
  }

  .mat-datepicker-content .mat-calendar {
    height: auto !important;

    .datepicker-message {
      padding: 8px;
    }
  }
}
