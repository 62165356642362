@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  .mat-app-background {
    background-color: mat.m2-get-color-from-palette($background, background) !important;
    color: mat.m2-get-color-from-palette($foreground, text) !important;
  }

  a {
    color: mat.m2-get-color-from-palette($primary, 400);
    text-decoration: none;

    &:hover, &:focus {
      color: mat.m2-get-color-from-palette($primary, 300);
    }
  }

  .selected {
    background-color: mat.m2-get-color-from-palette($accent) !important;

    mat-icon {
      color: mat.m2-get-contrast-color-from-palette($accent, 500) !important;
    }

    a {
      color: mat.m2-get-color-from-palette($background, dialog) !important;
      font-weight: bold !important;
    }
  }

  .warn {
    color: mat.m2-get-color-from-palette($warn) !important;
  }

  .mat-mdc-snack-bar-container {
    @include mat.elevation(0);

    &.no-action .mat-mdc-simple-snack-bar .mdc-snackbar__label {
      text-align: center;
    }

    &.alert-message .mat-mdc-snack-bar-label .mdc-snackbar__label {
      color: mat.m2-get-color-from-palette($warn);
    }
  }

  // InSight Toolbar
  .insight-toolbar {
    vertical-align: center;
    height: 50px;
    padding-left: 10px;
    background: mat.m2-get-color-from-palette($background, app-bar) !important;
    border: none;

    .mat-toolbar {
      background: mat.m2-get-color-from-palette($background, dialog) !important;
    }
  }

  .toolbar-component {
    vertical-align: center;
    margin: 0 10px 0 10px;
  }

  .toolbar-text {
    padding: 0 5px;
    font-size: 16px;
  }

  .toolbar-text-bold {
    @extend .toolbar-text;
    font-weight: bold;
  }

  .toolbar-text-secondary {
    @extend .toolbar-text;
    font-size: small;
    font-style: italic;
    color: mat.m2-get-color-from-palette($background, secondary-text);
  }

  .toolbar-spacer {
    flex: 1 1 auto;
  }

  // Info Bar Component
  .info-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;

    background: mat.m2-get-color-from-palette($background, dialog);

    .mat-divider.mat-divider-vertical {
      border-right-color: mat.m2-get-color-from-palette($primary) !important;
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }

  .info-bar-data {
    font-weight: bold;
    color: mat.m2-get-color-from-palette($accent);
  }

  .info-bar-component {
    @extend .bt-toggle;
    font-size: 12pt;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    line-height: 40px;
    justify-content: center;
    white-space: nowrap;
    color: mat.m2-get-color-from-palette($foreground, secondary-text);
  }

  .bt-toggle {
    .info-bar-toggle-label {
      line-height: 32px;
    }

    .mat-icon {
      padding: 12px 0 !important;
    }

    .mat-button-toggle-label-content {
      padding: 0 12px !important;
    }

    .mat-button-toggle {
      padding: 0;
      line-height: 32px !important;

      &.mat-button-toggle-checked {
        color: mat.m2-get-contrast-color-from-palette($primary, 500);
        background: mat.m2-get-color-from-palette($primary);
      }

      &.mat-button-toggle-disabled {
        background: mat.m2-get-color-from-palette($foreground, disabled-button);
      }
    }
  }

  .mat-vertical-stepper-header {
    padding: 24px 12px !important;
  }
  .mat-vertical-content-container {
    margin-left: 24px !important;
  }
  .mat-step-header .mat-step-icon-state-number {
    color: mat.m2-get-color-from-palette($primary, stepper-icon-text);
  }
  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    background-color: mat.m2-get-color-from-palette($primary);
    color: mat.m2-get-contrast-color-from-palette($primary, 500);
  }

  // Angular Split
  .as-split-gutter {
    background-color: unset !important;
  }

  .bt-split-gutter {
    width: 100%;
    height: 100%;
    background-color: mat.m2-get-color-from-palette($foreground, divider) !important;
    position: relative;
  }

  .bt-split-gutter-icon {
    height: 100%;
    width: 100%;
    background-color: black;
    transition: opacity 0.3s;
    opacity: 0;
    position: absolute;

    .dragged &,
    &:hover {
      opacity: 0.2;
      z-index: 100;
    }

    $shadeSize: 8px;

    .as-horizontal & {
      width: 1 + ($shadeSize * 2);
      height: 100%;
      left: -$shadeSize;
      right: $shadeSize;
    }

    .as-vertical & {
      height: 1 + ($shadeSize * 2);
      width: 100%;
      top: -$shadeSize;
      bottom: $shadeSize;
      left: 0;
    }
  }
}

html {
  overflow-y: initial !important;
}

html.cdk-global-scrollblock body {
  overflow-y: scroll;
}

body {
  font-family: "Roboto", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  margin: 0;
}

main {
  margin: 10px;
}

.hide {
  display: none;
}

.clickable {
  cursor: hand;
}

.wrap {
  white-space: normal;
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-mdc-list.dense-detail-list {
  .mat-mdc-list-item-title {
    font-size: 12px;
  }

  .mat-mdc-list-item-line {
    font-size: 12px;
  }
}

.mat-mdc-card-title {
  padding: 15px;
}

.header-text {
  font-size: 18px;
  font-weight: 500;
}

// Used to make MatSelect popups fit content width, not bound by width of the MatSelect itself
.selectPopupFull {
  min-width: max-content;
}
